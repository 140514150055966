/* eslint-disable jsx-a11y/anchor-has-content */
import React, { useCallback } from "react"
import { Link } from "gatsby"
import { postAppMsg, platformCheck } from "gpages/utils/native_bridge"

const Clickable = ({
  to,
  disabled,
  href,
  appNavTo,
  newWindow = true,
  ...restProps
}) => {
  const { onClick } = restProps
  const handleClick = useCallback(
    (e) => {
      platformCheck(
        () => {
          const link = appNavTo.startsWith("/")
            ? `${window.location.protocol}//${window.location.hostname}${appNavTo}`
            : appNavTo
          postAppMsg("navigate", { link })
          e.preventDefault()
        },
        () => {
          // window.location.href = appNavTo
        },
        {
          onlyInApp: /^app:\/\//.test(appNavTo),
        }
      )
      onClick?.()
    },
    [appNavTo, onClick]
  )

  const props = {
    ...restProps,
    ...(newWindow
      ? {
          target: "_blank",
        }
      : null),
  }

  if (disabled === true) {
    return <div {...props} />
  }

  if (to) {
    return <Link to={to} {...props} />
  }

  if (href) {
    return <a href={href} {...props} />
  }

  if (appNavTo) {
    return <a href={appNavTo} {...props} onClick={handleClick} />
  }

  if (onClick) {
    return <a role="button" tabIndex={0} {...props} />
  }

  return <div {...props} />
}

export default Clickable
