const appKey = process.env.GATSBY_APP
if (!appKey) {
  throw new Error("appKey 不能为空")
}

const title = "UNIC"
const desc = "Your Unique Connection with Chinese Gamers."

const cdnUrl = "https://site.gcores.com"

module.exports = {
  appKey,
  cdnUrl,
  pageUrl: `${cdnUrl}/${appKey}/`,
  appInfo: {
    name: title,
    description: desc,
    shareTitle: title,
    shareDescription: desc,
  },
}
