import React from "react"

export const icons = {
  logo: {
    viewBox: "0 0 28 27",
    customCode: (
      <>
        <g clipPath="url(#clip0_39_3777)">
          <path
            d="M22.2913 23.7781C22.4678 23.9546 22.4495 24.2529 22.2487 24.399C19.4306 26.4562 15.7939 27.4575 11.9045 26.7819C6.41751 25.8293 1.97736 21.3922 1.02177 15.9021C0.343116 12.0128 1.3474 8.37609 3.40466 5.55496C3.55074 5.35411 3.85202 5.33585 4.02549 5.51236L7.80524 9.29211C8.02132 9.50819 8.05479 9.83077 7.91176 10.1016C7.08703 11.6385 6.7827 13.4888 7.31832 15.4578C7.97871 17.8681 9.92641 19.8188 12.3367 20.4823C14.3057 21.024 16.1621 20.7166 17.702 19.8919C17.9698 19.7488 18.2954 19.7823 18.5115 19.9984L22.2913 23.7781ZM16.7221 0.204873C12.8327 -0.473779 9.19602 0.530505 6.3749 2.58776C6.17404 2.73384 6.15578 3.03513 6.33229 3.20859L10.1121 6.98835C10.3281 7.20442 10.6507 7.2379 10.9216 7.09486C12.4584 6.27318 14.3087 5.96581 16.2777 6.50142C18.688 7.16182 20.6418 9.10952 21.3022 11.5198C21.8439 13.4888 21.5365 15.3452 20.7118 16.8851C20.5688 17.1529 20.6022 17.4785 20.8183 17.6946L24.5981 21.4744C24.7746 21.6509 25.0728 21.6326 25.2189 21.4318C27.2762 18.6137 28.2804 14.977 27.6018 11.0877C26.6462 5.60061 22.2091 1.16351 16.7221 0.204873Z"
            fill="currentColor"
          />
        </g>
        <defs>
          <clipPath id="clip0_39_3777">
            <rect
              width="27"
              height="27"
              fill="white"
              transform="translate(0.812012 -0.00488281)"
            />
          </clipPath>
        </defs>
      </>
    ),
  },
  share: {
    viewBox: "0 0 200 200",
    customCode: (
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g>
          <g fill="#FFFFFF">
            <path
              d="M100,0 C44.771525,0 0,44.771525 0,100 C0,155.228475 44.771525,200 100,200 C155.228475,200 200,155.228475 200,100 C200,44.771525 155.228475,0 100,0 Z M100,28.5714286 C139.448911,28.5714286 171.428571,60.5510893 171.428571,100 C171.428571,139.448911 139.448911,171.428571 100,171.428571 C60.5510893,171.428571 28.5714286,139.448911 28.5714286,100 C28.5714286,60.5510893 60.5510893,28.5714286 100,28.5714286 Z"
              fillOpacity="0.2"
              fillRule="nonzero"
              opacity="0.5"
            />
            <path d="M100.303951,69.9088146 C117.090722,69.9088146 130.699088,83.5171809 130.699088,100.303951 C130.699088,117.090722 117.090722,130.699088 100.303951,130.699088 C83.5171809,130.699088 69.9088146,117.090722 69.9088146,100.303951 C69.9088146,83.5171809 83.5171809,69.9088146 100.303951,69.9088146 Z M109.3293,88.4836204 L94.4342148,88.4836204 L94.2491696,88.4896068 C92.7810976,88.584894 91.6198503,89.8058275 91.6198503,91.2979849 L91.6198503,91.2979849 L91.6258367,91.4830302 C91.7211239,92.9511022 92.9420575,94.1123494 94.4342148,94.1123494 L94.4342148,94.1123494 L102.422684,94.1116204 L88.6179918,107.917487 L88.4808668,108.064606 C87.5209917,109.170227 87.5667001,110.846308 88.6179918,111.8976 C89.7170695,112.996677 91.4990266,112.996677 92.5981043,111.8976 L92.5981043,111.8976 L106.514684,97.9796204 L106.514936,105.487277 L106.520922,105.672322 C106.616209,107.140394 107.837143,108.301641 109.3293,108.301641 C110.883631,108.301641 112.143665,107.041607 112.143665,105.487277 L112.143665,105.487277 L112.143665,91.2979849 L112.137678,91.1129397 C112.042391,89.6448677 110.821458,88.4836204 109.3293,88.4836204 L109.3293,88.4836204 Z" />
          </g>
          <g transform="translate(50.000000, 64.000000)" />
        </g>
      </g>
    ),
  },
}
