export const postAppMsg = (type, data) => {
  if (window.ReactNativeWebView) {
    const postData = JSON.stringify({
      type,
      data,
    });
    window.ReactNativeWebView.postMessage(postData);
  }
};

export const platformCheck = (
  taskInApp,
  taskInWeb,
  { needAdvanced = true, onlyInApp = false } = {}
) => {
  if (window.ReactNativeWebView) {
    if (!needAdvanced || window.GCORES_APP_BUILD_NUMBER) {
      taskInApp();
      return;
    }
    alert("此功能仅在最新版 App 提供，请更新。");
    return;
  }
  if (onlyInApp) {
    if (
      window.confirm(
        "此功能仅在 机核 移动端提供，请下载 机核APP 使用，是否前往下载？"
      )
    ) {
      window.location.href = "https://www.gcores.com/app";
    }
    return;
  }
  taskInWeb();
};
export const shareInApp = ({ isWXBrowser = false, shareData } = {}) => {
  platformCheck(
    () => {
      let data = window.getShareData?.();
      data =
        data || shareData ? { ...(data || {}), ...(shareData || {}) } : null;
      if (!data) {
        return;
      }

      postAppMsg("share", data);
    },
    () => {
      if (isWXBrowser) {
        alert("请点击 ... 以转发分享");
      } else if (window.confirm("请使用 App 访问，是否前往下载？")) {
        window.location.href = "https://www.gcores.com/app";
      }
    }
  );
};

export const jumpToSigninInApp = (pageUrl) => {
  platformCheck(
    () => {
      postAppMsg("navigate", {
        link: "app://gcores.Signin",
      });
    },
    () => {
      window.location.href = `https://www.gcores.com/account/signin?return=${encodeURIComponent(
        pageUrl
      )}`;
    }
  );
};

export const jumpToSettingsInApp = (pageUrl) => {
  platformCheck(
    () => {
      postAppMsg("navigate", {
        link: "app://gcores.Binding",
      });
    },
    () => {
      window.location.href = `https://www.gcores.com/settings`;
    }
  );
};
