/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useEffect } from "react"
import PropTypes from "prop-types"

import Seo from "@components/seo"

import * as styles from "./index.module.scss"
import AppContext from "@src/context/appContext"
import classnames from "gpages/utils/classnames"
import Icon from "@src/components/icon"
import Clickable from "@src/components/clickable"

const Layout = ({ children, location }) => {
  const [prepared, setPrepared] = React.useState(false)

  useEffect(() => {
    setTimeout(() => {
      setPrepared(true)
    }, 1000)
  }, [])
  return (
    <AppContext.Provider value={{}}>
      <Seo />
      <div
        className={classnames(styles.preparing, {
          [styles.prepared]: prepared,
        })}
      >
        <span>
          <Icon icon="logo" />
        </span>
        <span>LOADING...</span>
      </div>
      <div className={styles.container}>
        <main>{children}</main>
      </div>
      <footer className={styles.footer}>
        <div className={styles.footerLeft}></div>
        <div className={styles.footerRight}>
          <Clickable appNavTo="https://beian.miit.gov.cn/">
            京ICP备17068232号-5
          </Clickable>
        </div>
      </footer>
    </AppContext.Provider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
