import classnames from "gpages/utils/classnames"
import React from "react"
import * as styles from "./icon.module.scss"
import { icons } from "./icons"

const Wrapper = ({
  data: { code, viewBox, customCode },
  className,
  icon,
  ...restProps
}) => (
  <svg
    aria-hidden="true"
    focusable="false"
    className={classnames(styles.icon, "o_icon", className)}
    data-icon={icon}
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    viewBox={viewBox || "0 0 14 14"}
    {...restProps}
  >
    {customCode || <path fill="currentColor" d={code} />}
  </svg>
)

const Icon = ({ icon, ...props }) => {
  const gicon = icons[icon]
  if (!gicon) {
    return null
  }
  return <Wrapper icon={icon} data={gicon} {...props} />
}

export default Icon
