import camelcase from "camelcase";

const __ENV__ = process.env.NODE_ENV;
const ENABLE = true;
const token = "3586a4df558173fe";
let siteName = "unknown";

const sensors =
  typeof window !== "undefined" ? require("sa-sdk-javascript") : null;

export const initSensors = (appKey) => {
  if (!(ENABLE && sensors)) {
    return;
  }

  siteName = camelcase(appKey);
  sensors.init({
    sdk_url:
      "https://cdn.jsdelivr.net/npm/sa-sdk-javascript@1.14.14/sensorsdata.min.js",
    name: "sensors",
    server_url: `https://jihe.datasink.sensorsdata.cn/sa?token=${token}${
      __ENV__ === "production" ? "&project=production" : ""
    }`,
    is_track_single_page: false, // 不执行默认 pageView
    show_log: __ENV__ !== "production",
    heatmap: {
      // 是否开启点击图，默认为 default 表示开启，自动采集 $WebClick 事件，可以设置 'not_collect' 表示关闭
      // 需要 JSSDK 版本号大于 1.7
      clickmap: "not_collect",
      // 是否开启触达注意力图，默认为 default 表示开启，自动采集 $WebStay 事件，可以设置 'not_collect' 表示关闭
      // 需要 JSSDK 版本号大于 1.9.1
      scroll_notice_map: "not_collect",
    },
  });

  sensors.registerPage({
    platform: "h5",
    site_name: siteName,
  });
};

const sensorsSource = (source) =>
  source ? `h5.${siteName}.${source}` : `h5.${siteName}.unknown`;

export const pageView = (screenName, isLoadingFirstPage = false) => {
  if (!ENABLE) {
    return;
  }
  const options = screenName && { screenName: sensorsSource(screenName) };
  if (isLoadingFirstPage) {
    sensors.quick("autoTrack", options);
  } else {
    sensors.quick("autoTrackSinglePage", options);
  }
};

export const createTrackEvent = (trackEvent) => trackEvent(sensors);

// 下面写事件

// const trackEvent = () => {}
