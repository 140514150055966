/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

require("./src/styles/global.scss")
const { appKey } = require("@config")
const { initSensors, pageView } = require("gpages/utils/sensors")
const camelcase = require("camelcase").default

initSensors(appKey)
// const React = require("react")

// Logs when the client route changes
exports.onRouteUpdate = ({ location, prevLocation, ...props }) => {
  const pathname = location.pathname
  // console.log("new pathname", pathname)
  // console.log("old pathname", prevLocation ? prevLocation.pathname : null)
  // console.log("other props", props)

  // 等待页面 title 变化
  setTimeout(() => {
    pageView(
      pathname === "/" ? "home" : camelcase(pathname.replace(/\//g, "-")),
      !prevLocation
    )
  }, 300)
}
